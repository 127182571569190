import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import Select from "./Select";
import { Switch } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DeviceConnectionModal = ({
  isOpen,
  setIsOpen,
  selectedDevice,
  setSelectedDevice,
}) => {
  const [companies, setCompaines] = useState([]);
  const [device, setDevice] = useState({});

  const handleCompanies = async () => {
    const { data } = await instance.get("/company");

    if (data.status) {
      setCompaines(
        data.data.map((d) => {
          return { label: d.name, value: d._id };
        })
      );
    }
  };

  useEffect(() => {
    handleCompanies();
  }, []);

  useEffect(() => {
    if (selectedDevice._id) {
      setDevice({
        ...selectedDevice,
        company: selectedDevice?.company?._id || "",
      });
    }
  }, [selectedDevice, setSelectedDevice]);

  const onSubmit = async () => {
    if (selectedDevice._id) {
      const { data } = await instance.post(
        "/device/connect/" + device.company,
        {
          deviceId: device.uniqueId,
          name: device.name,
          isLocked: device.isLocked,
        }
      );

      if (data.status) {
        showToast(data.message, "success");
        setIsOpen(false);
      } else {
        showToast(data.message, "error");
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Cihaz Tanımla / Güncelle
                </Dialog.Title>
                <div className="flex flex-col mt-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Cihaz Id
                  </label>
                  <div className="mt-1">
                    <input
                      type="name"
                      name="name"
                      id="name"
                      className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                      placeholder=""
                      disabled
                      value={device.uniqueId}
                      onChange={(e) => {
                        setDevice({ ...device, uniqueId: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adı
                  </label>
                  <div className="mt-1">
                    <input
                      type="name"
                      name="name"
                      id="name"
                      className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                      placeholder=""
                      value={device.name}
                      onChange={(e) => {
                        setDevice({ ...device, name: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Bağlı Firma
                  </label>
                  <div className="mt-2">
                    <Select
                      value={device?.company || ""}
                      items={companies}
                      textPlaceholder={"Firma Sec"}
                      onChange={(v) => {
                        setDevice({ ...device, company: v });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between mt-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Kilit
                  </label>
                  <LockClosedIcon className="w-4 h-4 text-gray-700 mr-auto ml-2" />
                  <div className="mt-2">
                    <Switch
                      checked={device?.isLocked}
                      onChange={(v) => setDevice({ ...device, isLocked: v })}
                      className={classNames(
                        device?.isLocked ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          device?.isLocked ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => onSubmit()}
                  >
                    Güncelle
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeviceConnectionModal;
