import { DocumentRemoveIcon, XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Slide } from "react-toastify";
import { AddNewImage, CustomImage } from "../../components";
import SureModal from "../../components/SureModal";
import instance from "../../lib/client";
import showToast from "../../utils/showToast";

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const images = [
  {
    title: "IMG_4985.HEIC",
    size: "3.9 MB",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "IMG_4985.HEIC",
    size: "3.9 MB",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "IMG_4985.HEIC",
    size: "3.9 MB",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "IMG_4985.HEIC",
    size: "3.9 MB",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
  {
    title: "IMG_4985.HEIC",
    size: "3.9 MB",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  },
];

const Slider = () => {
  const [sliderItems, setSliderItems] = useState([]);
  const [sureModal, setSureModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleSliderItems = async () => {
    const { data } = await instance.get("/slider/main-slider");

    if (data.status) {
      console.log(data.data);
      setSliderItems(data.data);
    }
  };

  useEffect(() => {
    handleSliderItems();
  }, []);

  const addNewImage = async (url) => {
    const { data } = await instance.post("/slider/main-slider", {
      url: url,
    });

    if (data.status) {
      showToast(data.message, "success");
      handleSliderItems();
    } else {
      showToast(data.message, "error");
    }
  };

  const onSubmitSure = async () => {
    const { data } = await instance.get(
      `/slider/main-slider/delete/${selectedImage}`
    );

    if (data.status) {
      showToast(data.message, "success");
      handleSliderItems();
      setSureModal(false);
    } else {
      showToast(data.message, "error");
      setSureModal(false);
    }
  };

  const onCancelSure = () => {
    setSureModal(false);
    setSelectedImage("");
  };

  return (
    <ul
      role="list"
      className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8"
    >
      {sliderItems.map((image) => (
        <div key={image._id} className="relative rounded-lg">
          <div
            className="rounded-full bg-red-400 absolute right-0 top-0 p-1 hover:bg-red-500 cursor-pointer"
            onClick={() => {
              setSelectedImage(image._id);
              setSureModal(true);
            }}
          >
            <XIcon className="w-6 h-6 text-white" />
          </div>
          <CustomImage
            src={image.url}
            alt=""
            className=" object-cover w-full group-hover:opacity-75 rounded-lg h-[300px] max-h-[350px]"
          />
        </div>
      ))}

      <AddNewImage onAdded={addNewImage} />

      <SureModal
        open={sureModal}
        setOpen={setSureModal}
        onSubmit={onSubmitSure}
        onCancel={onCancelSure}
        firstText={"Resmi silmek istediginizden eminmisiniz?"}
        secondText={"Resmi sildiginiz taktirde ana slider'dan kaldirilacaktir."}
      />
    </ul>
  );
};

export default Slider;
