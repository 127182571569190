import React, { useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ArrowDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Select = ({
  items,
  value,
  onChange,
  className,
  placeholder,
  icon,
  colliderClassName = "",
  textPlaceholder,
  textClassName = "text-secondary-300",
  menuClassName = "",
  menuItemClassName = "",
}) => {
  return (
    <Listbox value={value} onChange={(i) => onChange(i.value)}>
      {({ open }) => (
        <>
          <div className={`relative ${colliderClassName}`}>
            <Listbox.Button
              className={`${
                open
                  ? ` ${
                      className
                        ? "bg-primary-100 border-primary-400 text-primary-400 " +
                          className
                        : "bg-primary-100 border-primary-400 text-primary-400"
                    } border-[1px] border-b-gray-300 !rounded-t-default !rounded-b-none`
                  : `${
                      className ? className : "bg-white border-gray-300"
                    } text-secondary-400  border-[1px]`
              } relative w-full border rounded-md items-center flex flex-row gap-1 shadow-sm pr-8 cursor-pointer py-[10px] px-4 text-left focus:outline-none focus:ring-0 sm:text-xs`}
            >
              {icon && icon}
              {placeholder && (
                <span className="text-gray-600">{placeholder}</span>
              )}
              <span className="block truncate">
                {items?.find((d) => d.value === value)?.label ||
                  textPlaceholder}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <ArrowDownIcon
                  className={`h-2 w-2  ${
                    open ? "-rotate-180 text-primary-400" : " text-gray-600"
                  } `}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-10  mt-0 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
                  open
                    ? "rounded-b-default border-primary-400 border-t-0 border-[1px]"
                    : "rounded-default"
                }`}
              >
                {items &&
                  items.map((item) => (
                    <Listbox.Option
                      key={item.value}
                      className={({ active }) =>
                        classNames(
                          item.value === value
                            ? "bg-white text-secondary-400"
                            : "text-secondary-400",
                          "cursor-pointer select-none relative hover:bg-purple-100 rounded-lg mx-1 py-2 pl-3 text-xs"
                        )
                      }
                      value={item}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              item.value === value
                                ? "font-semibold"
                                : "font-normal",
                              "block truncate"
                            )}
                          >
                            {item.label}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Select;
