import {
  Login,
  AuthNavigation,
  DashboardNavigation,
  Home,
  Company,
} from "./screens";
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  DeviceMobileIcon,
  DeviceTabletIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  InformationCircleIcon,
  MenuAlt2Icon,
  PhotographIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  Categories,
  Products,
  Devices,
  Personals,
  Settings,
  Contact,
  Slider,
} from "./screens/Dashboard";
import { CogIcon, UserGroupIcon } from "@heroicons/react/solid";
import Notifications from "./screens/Dashboard/Notifications";

const routes = [
  {
    path: "/auth",
    element: AuthNavigation,
    isPrivate: false,
    subRoutes: [
      {
        path: "login",
        element: Login,
      },
    ],
  },
  {
    path: "/dashboard",
    element: DashboardNavigation,
    isPrivate: true,
    subRoutes: [
      // {
      //   path: "home",
      //   name: "Anasayfa",
      //   element: Home,
      //   isVisible: true,
      //   icon: HomeIcon,
      // },
      {
        path: "slider",
        name: "Slider",
        element: Slider,
        isVisible: true,
        icon: PhotographIcon,
      },
      {
        path: "company",
        name: "Firmalar",
        element: Company,
        isVisible: true,
        icon: FolderIcon,
      },
      {
        path: "personals",
        name: "Personeller",
        element: Personals,
        isVisible: true,
        icon: UserGroupIcon,
      },
      {
        path: "products",
        name: "Ürünler",
        element: Products,
        isVisible: true,
        icon: InboxIcon,
      },

      {
        path: "categories",
        name: "Kategoriler",
        element: Categories,
        isVisible: true,
        icon: MenuAlt2Icon,
      },
      {
        path: "devices",
        name: "Cihazlar",
        element: Devices,
        isVisible: true,
        icon: DeviceTabletIcon,
      },
      {
        path: "notifications",
        name: "Bildirimler",
        element: Notifications,
        isVisible: true,
        icon: BellIcon,
      },
      {
        path: "contact",
        name: "İletişim Mesajları",
        element: Contact,
        isVisible: true,
        icon: InformationCircleIcon,
      },
      {
        path: "settings",
        name: "Ayarlar",
        element: Settings,
        isVisible: true,
        icon: CogIcon,
      },
    ],
  },
];

export default routes;
