import axios from "axios";
import authStore from "../stores/auth.store";

export const socketURL = "https://api.selectiveyazilim.com";
// export const socketURL = "http://localhost:3351";

export const api = "https://api.selectiveyazilim.com/candess/api";
// export const api = "http://localhost:3350/api";

const instance = axios.create({
  baseURL: api,
  timeout: 100000000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (value) => {
    if (value?.response?.status === 401) {
      authStore.signOut();
      window.location.href = "/auth/login";
    } else if (value?.response?.status === 403) {
      window.location.href = "/auth/login";
    } else {
      Promise.reject(value);
    }
  }
);

export default instance;
