import { MinusCircleIcon, SearchIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { CategoryModal, CustomImage } from "../../components";
import PersonalModal from "../../components/PersonalModal";
import SureModal from "../../components/SureModal";
import instance from "../../lib/client";
import showToast from "../../utils/showToast";

const Personals = () => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [personals, setPersonals] = useState([]);
  const [selectedPersonal, setSelectedPersonal] = useState({});
  const [sureModal, setSureModal] = useState(false);

  const onSearch = async (text) => {
    if (text) {
      const { data } = await instance.get("/personal/search/" + text);

      if (data.status) {
        setPersonals(data.data);
      } else {
        showToast(data.message);
      }
    } else {
      handlePersonals();
    }
  };

  const handlePersonals = async () => {
    const { data } = await instance.get("/personal");

    console.log(data.data);
    if (data.status) {
      setPersonals(data.data);
    }
  };

  const onDeleteStart = (personal) => {
    setSelectedPersonal(personal);
    setSureModal(true);
  };

  const onSubmitDelete = async () => {
    setSureModal(false);

    const { data } = await instance.get(
      "/personal/delete/" + selectedPersonal._id
    );

    if (data.status) {
      showToast(data.message, "success");
    } else {
      showToast(data.message, "error");
    }
    setTimeout(() => {
      setSelectedPersonal({});
    }, 500);

    handlePersonals();
  };

  const onCancelDelete = () => {
    setSureModal(false);

    setTimeout(() => {
      setSelectedPersonal({});
    }, 500);
  };

  const onEdit = (personal) => {
    setSelectedPersonal(personal);
    setIsOpen(true);
  };

  useEffect(() => {
    handlePersonals();
  }, []);

  return (
    <div>
      <div className="flex flex-row">
        <div className="relative flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Ara..."
            value={search}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(search);
              }
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div
            onClick={() => onSearch(search)}
            className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
          >
            <kbd className="inline-flex items-center cursor-pointer hover:bg-gray-100 border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
              <SearchIcon className="w-4 h-5" />
            </kbd>
          </div>
        </div>
        <button
          onClick={() => setIsOpen(true)}
          type="button"
          className="inline-flex items-center ml-auto justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Personal Ekle
        </button>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Adı
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Soyadı
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Durumu
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Gorevi
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {personals.map((personal) => (
                    <tr key={personal._id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <CustomImage
                              className="h-10 w-10 rounded-full"
                              src={personal.image}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {personal.name}
                            </div>
                            <div className="text-gray-500">
                              {personal.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">{personal.surname}</div>
                        <div className="text-gray-500">
                          {personal.department}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          Aktif
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                        <div className="mb-1">TR : {personal?.work?.tr}</div>
                        <div className="mb-1">EN : {personal?.work?.en}</div>
                        <div>RU : {personal?.work?.ru}</div>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span
                          onClick={() => onEdit(personal)}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        >
                          Düzenle
                        </span>
                        <span
                          onClick={() => onDeleteStart(personal)}
                          className="bg-red-400 ml-2 text-white p-2 hover:text-indigo-900 cursor-pointer rounded-md"
                        >
                          Sil
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <CategoryModal
        isOpen={isOpen}
        setIsOpen={() => {
          handlePersonals();

          setIsOpen(false);
          setTimeout(() => {
            setSelectedPersonal({});
          }, 500);
        }}
        selectedPersonal={selectedPersonal}
      /> */}
      <SureModal
        open={sureModal}
        setOpen={() => {
          setTimeout(() => {
            setSelectedPersonal({});
          }, 500);
          setSureModal(false);
        }}
        onSubmit={onSubmitDelete}
        onCancel={onCancelDelete}
        firstText={"Personeli silmek istediginizden eminmisiniz?"}
        secondText={
          selectedPersonal.name +
          " silindigi anda tum bagli oldugu cihaz ve firmalardan baglantisi koparilacaktir!"
        }
      />
      <PersonalModal
        isOpen={isOpen}
        setIsOpen={() => {
          setIsOpen(false);
          setTimeout(() => {
            setSelectedPersonal({});
          }, 500);

          handlePersonals();
        }}
        selectedPersonal={selectedPersonal}
      />
    </div>
  );
};

export default Personals;
