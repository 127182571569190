import { action, makeAutoObservable } from "mobx";
import { makePersistable, isHydrated, hydrateStore } from "mobx-persist-store";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: ["accessToken", "isLogged", "promoter", "user"],
      storage: localStorage,
    });
  }

  accessToken = "";
  user = {};
  isLogged = false;

  setLogged = (status) => {
    this.isLogged = status;
  };

  setAccessToken = (token) => {
    this.accessToken = token;
    this.setLogged(true);
  };

  setUser = (payload) => {
    this.user = payload;
  };

  signOut = () => {
    this.accessToken = "";
    this.setUser({});
    this.setLogged(false);
  };
}

const authStore = new AuthStore();

export default authStore;
