import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useContext, useEffect, useState } from "react";
import Map from "./Map";
import showToast from "../utils/showToast";
import useStore from "../hooks/useStore";
import { socket } from "../contexts/socketContext";

const MapAndNotificationModal = ({ isOpen, setIsOpen, device, setDevice }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [text, setText] = useState("");

  const [location, setLocation] = useState({
    long: 0,
    lat: 0,
  });

  const handleLocationInitial = () => {
    socket?.on("handle-location", (data) => {
      console.log(data.uniqueId)
      console.log(device.uniqueId)
      if (data.uniqueId === device.uniqueId) {
        console.log('MATCH LOCATION')
        console.log(data.location)
        setLocation(data.location);
      }else {
        setLocation({
          long:0,
          lat:0
        })
      }
    });
  };

  useEffect(() => {
    if (device?.uniqueId && isOpen) {
      handleLocation();
      handleLocationInitial();
    }
  }, [device, setDevice, isOpen, setIsOpen]);

  const handleLocation = () => {
    console.log('HANDLE LOCATION WORKS')
    socket.emit("get-location", {
      uniqueId: device.uniqueId,
    });
  };

  const onClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setLocation({
        long: 0,
        lat: 0,
      });
    }, 400);
  };

  const sendMessage = () => {
    socket.emit("send-notification", {
      uniqueId: device.uniqueId,
      message: text,
    });

    showToast("Mesaj gönderildi", "success");
    setText("");
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="w-full h-full flex flex-row rounded-md min-h-[600px] gap-4">

                  <Map
                    isMarkerShown
                    location={location}
                    setLocation={setLocation}
                  />

                  <div className="flex flex-col min-w-[200px]">
                    <div
                      onClick={() => onClose()}
                      className="ml-auto p-2 rounded-full bg-green-200 hover:bg-green-400 cursor-pointer"
                    >
                      <XIcon className="w-6 h-6" />
                    </div>

                    <div
                        onClick={() => handleLocation()}
                        className="w-full text-xs items-center justify-center text-center cursor-pointer py-2 bg-green-300 text-black items-center justify-center flex rounded-md mt-auto"
                    >
                      {device.name}  Konumu Güncelle
                    </div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bildirim Mesajı
                    </label>
                    <div className="mt-1">
                      <textarea
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 min-h-[300px] focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                        placeholder=""
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      onClick={() => sendMessage()}
                      className="w-full cursor-pointer py-2 bg-green-300 text-black items-center justify-center flex rounded-md mt-auto"
                    >
                      Bildirim Gönder
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MapAndNotificationModal;
