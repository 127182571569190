import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import ImageUploader from "./ImageUploader";
import Select from "./Select";

const defaultValues = {
  name: "",
  description: "",
  category: "",
  banner_image: "",
  images: [],
};

const imageElement = {
  name: "",
  url: "",
  description: "",
};

const ProductModal = ({ isOpen, setIsOpen, selectedProduct }) => {
  const [product, setProduct] = useState(defaultValues);
  const [categories, setCategories] = useState([]);

  const handleCategories = async () => {
    const { data } = await instance.get("/category/for-select");

    if (data.status) {
      setCategories(
        data.data.map((d) => {
          return { label: d.name, value: d._id };
        })
      );
    }
  };

  const addImage = () => {
    setProduct({
      ...product,
      images: [...product.images, imageElement],
    });
  };

  const removeImage = (index) => {
    setProduct({
      ...product,
      images: product.images.filter((_, i) => i !== index),
    });
  };

  const handleProductDetail = async () => {
    const { data } = await instance.get(
      `/product/detail/${selectedProduct._id}`
    );

    if (data.status) {
      setProduct({ ...data.data, category: data?.data?.category?._id || "" });
    } else {
      showToast(data.message, "error");
    }
  };

  useEffect(() => {
    handleCategories();
    if (selectedProduct && selectedProduct._id) {
      handleProductDetail();
    } else {
      setProduct(defaultValues);
    }
  }, [selectedProduct]);

  const onSubmit = async () => {
    if (!selectedProduct?._id) {
      const { data } = await instance.post("/product", product);
      if (data.status) {
        setIsOpen(false);
        showToast(data.message, "success");
      } else {
        showToast(data.message, "error");
      }
    } else {
      const { data } = await instance.post(
        `/product/update/${selectedProduct._id}`,
        product
      );
      if (data.status) {
        setIsOpen(false);
        showToast(data.message, "success");
      } else {
        showToast(data.message, "error");
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {selectedProduct?._id ? "Ürünü Güncelle" : "Ürün Ekle"}
                </Dialog.Title>
                <div className="grid grid-cols-2 gap-12 w-full">
                  <div>
                    <div>
                      <ImageUploader
                        image={product.banner_image}
                        text={"Banner Resmi"}
                        onChange={(imageUrl) => {
                          setProduct({ ...product, banner_image: imageUrl });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-5 mt-2 items-center">
                      <label
                        htmlFor="name"
                        className="block text-sm col-span-1 font-medium text-gray-700"
                      >
                        Adı (TR) :
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                        placeholder=""
                        value={product.name.tr}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            name: { ...product.name, tr: e.target.value },
                          });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-5 mt-4 items-center">
                      <label
                        htmlFor="name"
                        className="block text-sm col-span-1 font-medium text-gray-700"
                      >
                        Adı (EN) :
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                        placeholder=""
                        value={product.name.en}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            name: { ...product.name, en: e.target.value },
                          });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-5 mt-4 items-center">
                      <label
                        htmlFor="name"
                        className="block text-sm col-span-1 font-medium text-gray-700"
                      >
                        Adı (RU) :
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                        placeholder=""
                        value={product.name.ru}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            name: { ...product.name, ru: e.target.value },
                          });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-6 mt-4 items-center">
                      <label
                        htmlFor="name"
                        className="block text-sm col-span-2 font-medium text-gray-700"
                      >
                        Açıklama (TR) :
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                        placeholder=""
                        value={product.description.tr}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description: {
                              ...product.description,
                              tr: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-6 mt-4 items-center">
                      <label
                        htmlFor="name"
                        className="block text-sm col-span-2 font-medium text-gray-700"
                      >
                        Açıklama (EN) :
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                        placeholder=""
                        value={product.description.en}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description: {
                              ...product.description,
                              en: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-6 mt-4 items-center">
                      <label
                        htmlFor="name"
                        className="block text-sm col-span-2 font-medium text-gray-700"
                      >
                        Açıklama (RU) :
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                        placeholder=""
                        value={product.description.ru}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            description: {
                              ...product.description,
                              ru: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-5 mt-3 items-center">
                      <label
                        htmlFor="name"
                        className="block text-sm col-span-1 font-medium text-gray-700"
                      >
                        Doküman :
                      </label>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                        placeholder=""
                        value={product.document}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            document: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center mt-2">
                      <label
                        htmlFor="name"
                        className="block text-sm min-w-[80px] font-medium text-gray-700"
                      >
                        Kategori :
                      </label>
                      <div className="mt-1 w-full">
                        <Select
                          value={product.category}
                          items={categories}
                          textPlaceholder={"Kategori Sec"}
                          onChange={(v) => {
                            setProduct({ ...product, category: v });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col mt-2">
                      {product?.images?.length > 0 && (
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Resimler
                        </label>
                      )}
                      <div className="mt-1 flex flex-col">
                        {product.images?.map((image, index) => {
                          return (
                            <div
                              key={index}
                              className="gap-2 border-gray-100 rounded-md px-2 flex flex-row"
                            >
                              <div className="max-h-full">
                                <ImageUploader
                                  noText
                                  image={image.url}
                                  onChange={(newUrl) => {
                                    setProduct({
                                      ...product,
                                      images: product.images.map(
                                        (i, iindex) => {
                                          if (iindex === index) {
                                            return { ...i, url: newUrl };
                                          }
                                          return i;
                                        }
                                      ),
                                    });
                                  }}
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <input
                                  type="name"
                                  name="name"
                                  id="name"
                                  className="shadow-sm px-2 placeholder:text-xs py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                                  placeholder="Resim Adı"
                                  value={image.name}
                                  onChange={(e) => {
                                    setProduct({
                                      ...product,
                                      images: product.images.map(
                                        (i, iindex) => {
                                          if (iindex === index) {
                                            return {
                                              ...i,
                                              name: e.target.value,
                                            };
                                          }
                                          return i;
                                        }
                                      ),
                                    });
                                  }}
                                />
                                <textarea
                                  type="textarea"
                                  name="name"
                                  id="name"
                                  className="shadow-sm px-2 placeholder:text-xs py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                                  placeholder="Resim Açıklaması"
                                  value={image.description}
                                  onChange={(e) => {
                                    setProduct({
                                      ...product,
                                      images: product.images.map(
                                        (i, iindex) => {
                                          if (iindex === index) {
                                            return {
                                              ...i,
                                              description: e.target.value,
                                            };
                                          }
                                          return i;
                                        }
                                      ),
                                    });
                                  }}
                                />
                                <button
                                  onClick={() => removeImage(index)}
                                  className="text-xs w-full py-2 bg-red-400 text-white rounded-md"
                                >
                                  Resmi Kaldır
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <button
                        type="button"
                        className="inline-flex mt-2 text-xs justify-center rounded-md w-full border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => addImage()}
                      >
                        {"Yeni Resim Ekle"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md w-full border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => onSubmit()}
                  >
                    {selectedProduct?._id ? "Güncelle" : "Ekle"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ProductModal;
