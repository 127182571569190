import { action, makeAutoObservable } from "mobx";
import { makePersistable, isHydrated, hydrateStore } from "mobx-persist-store";

class LayoutStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "LayoutStore",
      //   properties: ["loading", "isLogged", "promoter", "user"],
      storage: localStorage,
    });
  }

  loading = false;

  setLoading = (status) => {
    this.loading = status;
  };
}

const layoutStore = new LayoutStore();

export default layoutStore;
