const instance = require("../lib/client");

class AuthService {
  setAuthorizationToken(token) {
    instance.default.defaults.headers.common.Authorization = `${token}`;
  }

  setUnauthorizedInterceptor(value) {}
}
const authService = new AuthService();

export default authService;
