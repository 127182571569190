import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import ImageUploader from "./ImageUploader";
import Select from "./Select";

const defaultValues = {
  name: "",
  surname: "",
  email: "",
  work: {
    en: "",
    tr: "",
    ru: "",
  },
  phone: "",
  company: "",
};

const PersonalModal = ({ isOpen, setIsOpen, selectedPersonal }) => {
  const [personal, setPersonal] = useState(defaultValues);
  const [companies, setCompanies] = useState([]);

  const handleCompanies = async () => {
    // const { data } = await instance.get("/company");
    // if (data.status) {
    //   setCompanies(
    //     data.data.map((d) => {
    //       return { label: d.name, value: d._id };
    //     })
    //   );
    // }
  };

  useEffect(() => {
    handleCompanies();
    if (selectedPersonal && selectedPersonal._id) {
      setPersonal({
        ...selectedPersonal,
        company: selectedPersonal.company?._id || "",
      });
    } else {
      setPersonal(defaultValues);
    }
  }, [selectedPersonal]);

  const onSubmit = async () => {
    if (!selectedPersonal?._id) {
      const { data } = await instance.post("/personal", personal);
      if (data.status) {
        setIsOpen(false);
        showToast(data.message, "success");
      } else {
        showToast(data.message, "error");
      }
    } else {
      const { data } = await instance.put(
        `/personal/${selectedPersonal._id}`,
        personal
      );
      if (data.status) {
        setIsOpen(false);
        showToast(data.message, "success");
      } else {
        showToast(data.message, "error");
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex-row grid grid-cols-2 gap-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 col-span-1"
                  >
                    {selectedPersonal?._id
                      ? "Personeli Güncelle"
                      : "Personel Ekle"}
                  </Dialog.Title>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 col-span-1"
                  >
                    Gorevi
                  </Dialog.Title>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <div>
                      <ImageUploader
                        image={personal.image}
                        text={"Resim"}
                        onChange={(imageUrl) => {
                          setPersonal({ ...personal, image: imageUrl });
                        }}
                      />
                    </div>
                    {/* <div className="flex flex-col mt-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Firma
                  </label>
                  <div className="mt-1">
                    <Select
                      value={personal.company}
                      items={companies}
                      textPlaceholder={"Firma Seç"}
                      onChange={(v) => {
                        setPersonal({ ...personal, company: v });
                      }}
                    />
                  </div>
                </div> */}

                    <div className="flex flex-col mt-2">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Adı
                      </label>
                      <div className="mt-1">
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                          placeholder=""
                          value={personal.name}
                          onChange={(e) => {
                            setPersonal({ ...personal, name: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mt-2">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Soyadı
                      </label>
                      <div className="mt-1">
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                          placeholder=""
                          value={personal.surname}
                          onChange={(e) => {
                            setPersonal({
                              ...personal,
                              surname: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col mt-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Telefon
                      </label>
                      <div className="mt-1">
                        <input
                          type="phone"
                          name="phone"
                          id="phone"
                          className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                          placeholder=""
                          value={personal.phone}
                          onChange={(e) => {
                            setPersonal({
                              ...personal,
                              phone: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mt-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        E-Posta
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                          placeholder=""
                          value={personal.email}
                          onChange={(e) => {
                            setPersonal({
                              ...personal,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="flex flex-col mt-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Turkce
                      </label>
                      <div className="mt-1">
                        <input
                          type="tr"
                          name="tr"
                          id="tr"
                          className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                          placeholder=""
                          value={personal.work.tr}
                          onChange={(e) => {
                            setPersonal({
                              ...personal,
                              work: {
                                ...personal.work,
                                tr: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="flex flex-col mt-2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Ingilizce
                        </label>
                        <div className="mt-1">
                          <input
                            type="en"
                            name="en"
                            id="en"
                            className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                            placeholder=""
                            value={personal.work.en}
                            onChange={(e) => {
                              setPersonal({
                                ...personal,
                                work: {
                                  ...personal.work,
                                  en: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mt-2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rusca
                        </label>
                        <div className="mt-1">
                          <input
                            type="ru"
                            name="ru"
                            id="ru"
                            className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                            placeholder=""
                            value={personal.work.ru}
                            onChange={(e) => {
                              setPersonal({
                                ...personal,
                                work: {
                                  ...personal.work,
                                  ru: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md w-full border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => onSubmit()}
                  >
                    {selectedPersonal?._id ? "Guncelle" : "Ekle"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PersonalModal;
