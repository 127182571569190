import { PhotographIcon, PlusIcon } from "@heroicons/react/outline";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactLoading from "react-loading";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import CustomImage from "./CustomImage";

const AddNewImage = ({ onAdded }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("file", acceptedFiles[0]);

    const { data } = await instance.post("/uploader", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status) {
      onAdded(data.data);
    } else {
      showToast("Resim yuklenemedi", "error");
    }

    setLoading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (loading)
    return (
      <div className="relative w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center justify-center">
        {loading && <ReactLoading type="spin" color="gray" />}
      </div>
    );
  return (
    <button
      type="button"
      className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      {...getRootProps()}
    >
      <div className="relative mx-auto w-5 h-5 ">
        <PlusIcon className=" w-7 h-7 text-gray-500 max-auto absolute -bottom-6 left-3 rounded-full bg-green-400 p-1" />
      </div>
      <PhotographIcon className="w-12 h-12 text-gray-500 mx-auto" />
      <input
        id="file-upload"
        name="file-upload"
        type="file"
        className="sr-only"
        {...getInputProps()}
      />
      <span className="mt-2 block text-sm font-medium text-gray-900">
        Yeni Resim Ekle
      </span>
    </button>
  );
};

export default AddNewImage;
