import React, { useEffect, useState } from "react";
import { CandessLogo, LoginOverlay } from "../../assets";
import instance from "../../lib/client";
import useStore from "../../hooks/useStore";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import authService from "../../services/auth.service";

const Login = () => {
  const { auth, layout } = useStore();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    username: false,
    password: false,
  });

  const onSubmit = async () => {
    layout.setLoading(true);
    if (!username) {
      setErrors({ ...errors, username: true });
      layout.setLoading(false);
    } else if (!password) {
      setErrors({ ...errors, password: true });
      layout.setLoading(false);
    } else {
      setErrors({ username: false, password: false });
      const { data } = await instance.post("/user/login", {
        username: username,
        password: password,
      });

      if (data.status) {
        const jwtDecoded = await jwtDecode(data.token);
        authService.setAuthorizationToken(data.token);
        auth.setAccessToken(data.token);
        auth.setLogged(true);
        auth.setUser(jwtDecoded.username);
        navigate("/dashboard/company");
      }
      layout.setLoading(false);
    }
  };

  return (
    <>
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src={CandessLogo} alt="Workflow" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Giriş Yap
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div>
                  <div className="mb-2">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Posta Adresi
                    </label>
                    <div className="mt-1">
                      <input
                        id="username"
                        name="username"
                        type="username"
                        autoComplete="username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className={`appearance-none block w-full px-3 py-2 border ${
                          errors.username ? "border-red-600" : "border-gray-300"
                        } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Şifre
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className={`appearance-none block w-full px-3 py-2 border ${
                          errors.password ? "border-red-600" : "border-gray-300"
                        } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full mt-8 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 transition-all"
                      onClick={() => onSubmit()}
                    >
                      Giriş Yap
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={LoginOverlay}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Login;
