import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { LocationMarkerIcon } from "@heroicons/react/solid";

const Map = ({ location, setLocation }) => {
  const [markers, setMarkers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [mapKey, setMapKey] = useState(false);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 40.0963247,
      lng: 29.4554671,
    },
    zoom: 11,
  });

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: location.lat, lng: location.long },
      map,
      title: "Device",
    });

    return marker;
  };

  useEffect(() => {
    setMapKey(!mapKey);
  }, [location, setLocation]);

  return (
    <div style={{ height: 600, width: "100%", borderRadius: "12px" }}>
      <GoogleMapReact
        key={mapKey}
        bootstrapURLKeys={{
          key: "AIzaSyAwO-HcmHd2bOXNlZjzDBXO_DYjkGijPqA",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        zoom={location?.lang || location?.lat ? 11 : 11}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </div>
  );
};

export default Map;
