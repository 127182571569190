import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import ImageUploader from "./ImageUploader";
import Select from "./Select";

const CompanyModal = ({ isOpen, setIsOpen, edit, setEdit }) => {
  const [company, setCompany] = useState({
    name: "",
    logo: "",
    personals: [],
  });
  const [personals, setPersonals] = useState([]);

  const handlePersonals = async () => {
    const { data } = await instance.get("/personal");

    if (data.status) {
      setPersonals(
        data.data.map((d) => {
          return {
            label: d.name + " " + d.surname,
            value: d._id,
          };
        })
      );
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    handlePersonals();
    if (edit.isEdit) {
      setCompany(edit.company);
    } else {
      setCompany({
        name: "",
        logo: "",
        personals: [],
      });
    }
  }, [setIsOpen, isOpen, edit, setEdit]);

  const onSubmit = async () => {
    if (!edit.isEdit) {
      const { data } = await instance.post("/company", company);

      if (data.status) {
        showToast(data.message, "success");
        setIsOpen(false);
      } else {
        showToast(data.message, "error");
      }
    } else {
      const { data } = await instance.post(
        `/company/${edit.company._id}`,
        company
      );

      if (data.status) {
        showToast(data.message, "success");
        setIsOpen(false);
        setEdit({ isEdit: false, company: {} });
      } else {
        showToast(data.message, "error");
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium flex col-span-3 flex-row justify-between leading-6 text-gray-900"
                >
                  <span>{edit ? "Firma Düzenle" : "Firma Ekle"}</span>
                  <div
                    className="rounded-full p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  >
                    <XIcon className="w-6 h-6" />
                  </div>
                </Dialog.Title>
                <div className="grid grid-cols-5 ">
                  <div className="mt-4 flex flex-col gap-4 col-span-3">
                    <ImageUploader
                      image={company.logo || edit.company.logo}
                      text={"Logo"}
                      onChange={(imageUrl) => {
                        setCompany({ ...company, logo: imageUrl });
                      }}
                    />

                    <div className="flex flex-col">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Firma Adı
                      </label>
                      <div className="mt-1">
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                          placeholder=""
                          value={company.name}
                          onChange={(e) => {
                            setCompany({ ...company, name: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="mt-4 flex flex-col pl-8 gap-4">
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Personeller
                        </label>
                        <div className="flex flex-col gap-2">
                          {personals.map((personal) => {
                            return (
                              <div
                                key={personal._id}
                                onClick={() => {
                                  if (
                                    company.personals.includes(personal.value)
                                  ) {
                                    setCompany({
                                      ...company,
                                      personals: company.personals.filter(
                                        (p) => p !== personal.value
                                      ),
                                    });
                                  } else {
                                    setCompany({
                                      ...company,
                                      personals: [
                                        ...company.personals,
                                        personal.value,
                                      ],
                                    });
                                  }
                                }}
                                className={
                                  "flex flex-row justify-between cursor-pointer items-center text-xs p-2 bg-green-300 w-full rounded-md hover:bg-green-400 transition-all"
                                }
                              >
                                <span>{personal.label}</span>
                                {company.personals.includes(personal.value) && (
                                  <CheckCircleIcon className="w-4 h-4" />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSubmit}
                  >
                    {edit ? "Kaydet" : "Ekle"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CompanyModal;
