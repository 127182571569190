import React from "react";
import authStore from "../stores/auth.store";
import layoutStore from "../stores/layout.store";
import { socketURL } from "../lib/client";

const storeContext = React.createContext({
  auth: authStore,
  layout: layoutStore,
});

export default storeContext;
