import { useEffect, useState } from "react";
import { CompanyModal, CustomImage } from "../../components";
import instance from "../../lib/client";

/* This example requires Tailwind CSS v2.0+ */
const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  // More people...
];

const Company = () => {
  const [companies, setCompanies] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState({
    isEdit: false,
    company: {},
  });

  const handleCompanies = async () => {
    const { data } = await instance.get("/company");

    if (data.status) {
      setCompanies(data.data);
    }
  };

  useEffect(() => {
    handleCompanies();
  }, []);

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="mt-4 sm:mt-0 ml-auto sm:flex-none">
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Firma Ekle
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className=" overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Firma adı
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {/* Title */}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Aktiflik Durumu
                    </th>
                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th> */}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {companies.map((company, i) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <CustomImage
                              className="h-10 w-10 object-cover rounded-full"
                              src={company.logo}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {company.name}
                            </div>
                            <div className="text-gray-500">{company.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {/* <div className="text-gray-900">{company.title}</div> */}
                        <div className="text-gray-500">
                          {company.department}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          Aktif
                        </span>
                      </td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {company.role}
                      </td> */}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          onClick={() => {
                            setEdit({
                              isEdit: true,
                              company: company,
                            });
                            setIsOpen(true);
                          }}
                        >
                          Düzenle
                          <span className="sr-only">, {company.name}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CompanyModal
        isOpen={isOpen}
        setIsOpen={() => {
          handleCompanies();
          setTimeout(() => {
            setEdit({
              isEdit: false,
              company: {},
            });
          }, 500);
          setIsOpen(false);
        }}
        edit={edit}
        setEdit={setEdit}
      />
    </div>
  );
};

export default Company;
