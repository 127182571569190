import { MinusCircleIcon, SearchIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { CategoryModal, CustomImage } from "../../components";
import SureModal from "../../components/SureModal";
import instance from "../../lib/client";
import showToast from "../../utils/showToast";

/* This example requires Tailwind CSS v2.0+ */
const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    department: "Optimization",
    email: "lindsay.walton@example.com",
    role: "Member",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More people...
];

const Notifications = () => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [sureModal, setSureModal] = useState(false);

  const onSearch = async (text) => {
    if (text) {
      const { data } = await instance.get("/notification/search/" + text);

      if (data.status) {
        setNotifications(data.data);
      } else {
        showToast(data.message);
      }
    } else {
      handleNotifications();
    }
  };

  const handleNotifications = async () => {
    const { data } = await instance.get("/notification");

    console.log(data.data);
    if (data.status) {
      setNotifications(data.data);
    }
  };

  useEffect(() => {
    handleNotifications();
  }, []);

  return (
    <div>
      <div className="flex flex-row">
        <div className="relative flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Ara..."
            value={search}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(search);
              }
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div
            onClick={() => onSearch(search)}
            className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
          >
            <kbd className="inline-flex items-center cursor-pointer hover:bg-gray-100 border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
              <SearchIcon className="w-4 h-5" />
            </kbd>
          </div>
        </div>
        <button
          onClick={() => setIsOpen(true)}
          type="button"
          className="inline-flex items-center ml-auto justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Bildirim Gonder
        </button>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Resim
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Icerik
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Gorulme
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Cihaz
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {notifications.map((notification) => (
                    <tr key={notification._id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <CustomImage
                              className="h-10 w-10 rounded-full"
                              src={notification.image}
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">{notification.text}</div>
                        <div className="text-gray-500">
                          {notification.department}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {notification.isSeen ? (
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            Goruldu
                          </span>
                        ) : (
                          <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            Gorulmedi
                          </span>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {notification.device.name}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
