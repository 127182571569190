import React, { useEffect, useState } from "react";
import useStore from "../../hooks/useStore";
import instance from "../../lib/client";
import showToast from "../../utils/showToast";

const Settings = () => {
  const { layout } = useStore();
  const [document, setDocument] = useState({
    site: "",
    catalog: "",
    videos: "",
  });

  const handleData = async () => {
    const { data } = await instance.get("/slider/catalog");

    console.log(data);
    if (data.status) {
      setDocument(data.data);
    }
  };

  const saveIt = async () => {
    const { data } = await instance.post("/slider/catalog", document);

    if (data.status) {
      showToast(data.message, "success");
    } else {
      showToast(data.message, "error");
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 xs:grid-cols-1 md:px-0 xs:px-12">
      <div className="col-span-2 flex flex-col border p-4">
        <div className="xl:col-span-1 lg:col-span-2 md:col-span-2 xs:col-span-1 grid grid-cols-6 mt-4 items-center">
          <label
            htmlFor="name"
            className="block text-sm col-span-2 font-medium text-gray-700"
          >
            Katalog
          </label>
          <input
            type="name"
            name="name"
            id="name"
            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
            placeholder=""
            value={document.catalog}
            onChange={(e) => {
              setDocument({ ...document, catalog: e.target.value });
            }}
          />
        </div>
        <div className="xl:col-span-1 lg:col-span-2 md:col-span-2 xs:col-span-1 col-span-1 grid grid-cols-6 mt-4 items-center">
          <label
            htmlFor="name"
            className="block text-sm col-span-2 font-medium text-gray-700"
          >
            Site
          </label>
          <input
            type="name"
            name="name"
            id="name"
            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
            placeholder=""
            value={document.site}
            onChange={(e) => {
              setDocument({ ...document, site: e.target.value });
            }}
          />
        </div>
        <div className=" xl:col-span-1 lg:col-span-2 md:col-span-2 xs:col-span-1col-span-1 grid grid-cols-6 mt-4 items-center">
          <label
            htmlFor="name"
            className="block text-sm col-span-2 font-medium text-gray-700"
          >
            Videolar
          </label>
          <input
            type="name"
            name="name"
            id="name"
            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
            placeholder=""
            value={document.videos}
            onChange={(e) => {
              setDocument({ ...document, videos: e.target.value });
            }}
          />
        </div>
        <button
          onClick={() => saveIt()}
          className="text-sm w-full py-2 shadow mt-4 bg-green-400 text-white rounded-md"
        >
          Kaydet
        </button>
      </div>
    </div>
  );
};

export default Settings;
