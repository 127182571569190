import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { socket } from "../contexts/socketContext";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import ImageUploader from "./ImageUploader";

const DeviceNotificationModal = ({ isOpen, setIsOpen, selectedDevices }) => {
  const [message, setMessage] = useState({
    image: "",
    text: "",
  });

  const onSubmit = async () => {
    selectedDevices.forEach((device) => {
      socket.emit("send-notification", {
        uniqueId: device,
        message: message.text,
        image: message.image,
      });
    });

    showToast("Bildirim gonderildi!", "success");
    setIsOpen(false);
    setTimeout(() => {
      setMessage({
        image: "",
        text: "",
      });
    }, 500);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium flex flex-row justify-between leading-6 text-gray-900"
                >
                  <span>{"Toplu Bildirim Gonder"}</span>
                  <div
                    className="rounded-full p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  >
                    <XIcon className="w-6 h-6" />
                  </div>
                </Dialog.Title>
                <div className="mt-4 flex flex-col gap-4">
                  <ImageUploader
                    image={message.image}
                    text={"Resim"}
                    onChange={(imageUrl) => {
                      setMessage({ ...message, image: imageUrl });
                    }}
                  />

                  <div className="flex flex-col">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mesaj
                    </label>
                    <div className="mt-1">
                      <textarea
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1 min-h-[250px]"
                        placeholder=""
                        value={message.text}
                        onChange={(e) => {
                          setMessage({ ...message, text: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSubmit}
                  >
                    {"Gönder"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeviceNotificationModal;
