import { SearchIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import {
  DeviceConnectionModal,
  DeviceNotificationModal,
  Map,
  MapAndNotificationModal,
} from "../../components";
import Select from "../../components/Select";
import SureModal from "../../components/SureModal";
import instance from "../../lib/client";
import showToast from "../../utils/showToast";

/* This example requires Tailwind CSS v2.0+ */
const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    department: "Optimization",
    email: "lindsay.walton@example.com",
    role: "Member",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More people...
];

const statuses = [
  {
    label: "Hepsi",
    value: null,
  },
  {
    label: "Tanımlı",
    value: true,
  },
  {
    label: "Tanımsız",
    value: false,
  },
];

const Devices = () => {
  const [devices, setDevices] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isMap, setIsMap] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);

  const onSearch = async (text, s) => {
    const { data } = await instance.post("/device/search", {
      status: s,
      keyword: text,
    });

    if (data.status) {
      setDevices(data.data);
    }
  };

  const [sureModal, setSureModal] = useState(false);

  const handleDevices = async () => {
    const { data } = await instance.get("/device");

    if (data.status) {
      setDevices(data.data);
    }
  };

  const onMakeUnuse = (device) => {
    setSelectedDevice(device);
    setSureModal(true);
  };

  const onSubmitUnuse = async () => {
    const { data } = await instance.get(
      "/device/deconnect/" + selectedDevice._id
    );

    if (data.status) {
      showToast(data.message, "success");
      handleDevices();
      setSureModal(false);
      setTimeout(() => {
        setSelectedDevice({});
      }, 500);
    } else {
      showToast(data.message, "error");
    }
  };

  const onCancelUnuse = () => {
    setSureModal(false);
    setTimeout(() => {
      setSelectedDevice({});
    }, 300);
  };

  const onEdit = (device) => {
    setSelectedDevice(device);

    setIsOpen(true);
  };

  useEffect(() => {
    handleDevices();
  }, []);

  return (
    <div className=" flex flex-col">
      <div className="flex flex-row">
        <div className="relative flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Ara..."
            value={search}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(search, status);
              }
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div
            onClick={() => onSearch(search)}
            className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
          >
            <kbd className="inline-flex items-center cursor-pointer hover:bg-gray-100 border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
              <SearchIcon className="w-4 h-5" />
            </kbd>
          </div>
        </div>
        <div>
          <button
            className="text-xs py-2.5 bg-gray-700 px-4 text-white rounded-md ml-4 hover:bg-gray-300 hover:text-black transition-all"
            onClick={() => {
              if (selectedDevices.length > 0) {
                setIsOpenNotification(true);
              } else {
                showToast("Lütfen en az bir cihaz seçiniz.", "error");
              }
            }}
          >
            Bildirim Gönder
          </button>
        </div>
        <Select
          items={statuses}
          textPlaceholder="Durum"
          colliderClassName="ml-auto"
          value={status}
          onChange={(e) => {
            setStatus(e);

            onSearch(search, e);
          }}
        />
      </div>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle md:px-1 lg:px-1">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="relative w-12 px-6 sm:w-16 sm:px-8"
                  >
                    <input
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                      checked={selectedDevices.length === devices.length}
                      onChange={() => {
                        if (selectedDevices.length === devices.length) {
                          setSelectedDevices([]);
                        } else {
                          setSelectedDevices(devices.map((d) => d.uniqueId));
                        }
                      }}
                    />
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Cihaz Adı
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Cihaz Id
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Tanım Durumu
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Bağlı Firma
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {devices.map((device) => (
                  <tr key={device._id}>
                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                      {selectedDevices.includes(device.uniqueId) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                      )}
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        value={device.uniqueId}
                        checked={selectedDevices.includes(device.uniqueId)}
                        onChange={(e) =>
                          setSelectedDevices(
                            !selectedDevices.includes(device.uniqueId)
                              ? [...selectedDevices, device.uniqueId]
                              : selectedDevices.filter(
                                  (p) => p !== device.uniqueId
                                )
                          )
                        }
                      />
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="">
                          <div className="font-medium text-gray-900">
                            {device.name || "Tanimsiz Cihaz"}
                          </div>
                          <div className="text-gray-500">{device.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="text-gray-900">{device.uniqueId}</div>
                      <div className="text-gray-500">{device.department}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        className={`inline-flex rounded-full ${
                          device.isConnected
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        } px-2 text-xs font-semibold leading-5 `}
                      >
                        {device.isConnected ? "Tanimli" : "Tanimsiz"}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {device?.company?.name || "Tanimsiz Firma"}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <span
                        onClick={() => {
                          setSelectedDevice(device);
                          setIsMap(true);
                        }}
                        className="text-white hover:text-gray-200 bg-green-600 hover:bg-green-700 transition-all p-2 rounded-md cursor-pointer mr-2 "
                      >
                        Konum
                      </span>
                      <span
                        onClick={() => onEdit(device)}
                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      >
                        Düzenle
                        <span className="sr-only">, {device.name}</span>
                      </span>
                      {device.isConnected && (
                        <span
                          onClick={() => onMakeUnuse(device)}
                          className="text-white bg-red-400 hover:bg-red-600 transition-all p-2 rounded-md hover:text-white cursor-pointer ml-2"
                        >
                          Tanımsız Yap
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DeviceConnectionModal
        isOpen={isOpen}
        setIsOpen={() => {
          setTimeout(() => {
            setSelectedDevice({});
          }, 500);
          handleDevices();
          setIsOpen(false);
        }}
        setSelectedDevice={setSelectedDevice}
        selectedDevice={selectedDevice}
      />

      <SureModal
        open={sureModal}
        setOpen={() => {
          setTimeout(() => {
            setSelectedDevice({});
          }, 500);
          setSureModal();
        }}
        firstText={"Cihazınızı tanımsız yapmak istediğinizden eminmisiniz?"}
        secondText={`${selectedDevice?.uniqueId} id li cihazınız tanımsız hale gelecektir. Cıhazınızı tanımsız yaptığınız taktirde uygulama cihazda kullanılmaz hale gelecektir!`}
        onSubmit={onSubmitUnuse}
        onCancel={onCancelUnuse}
        submitText={"Tanımsız Yap"}
        cancelText={"İptal"}
      />
      <MapAndNotificationModal
        isOpen={isMap}
        device={selectedDevice}
        setDevice={setSelectedDevice}
        setIsOpen={() => {
          setTimeout(() => {
            setSelectedDevice({});
          }, 500);
          setIsMap(false);
        }}
      />

      <DeviceNotificationModal
        setIsOpen={setIsOpenNotification}
        isOpen={isOpenNotification}
        selectedDevices={selectedDevices}
      />
    </div>
  );
};

export default Devices;
