/* This example requires Tailwind CSS v2.0+ */
import {
  MailIcon,
  PhoneIcon,
  MinusCircleIcon,
  PencilIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { ProductModal } from "../../components";
import CustomImage from "../../components/CustomImage";
import SureModal from "../../components/SureModal";
import instance from "../../lib/client";
import showToast from "../../utils/showToast";

const people = [
  {
    name: "Jane Cooper",
    title: "Paradigm Representative",
    role: "Admin",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  // More people...
];

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [sureModal, setSureModal] = useState(false);
  const [search, setSearch] = useState("");

  const onSearch = async (text) => {
    if (text) {
      setSearch(text);

      const { data } = await instance.get(`/product/search/${text}`);

      if (data.status) {
        setProducts(data.data);
        setFilteredProducts(data.data);
      }
    } else {
      setSearch("");
      handleProducts();
    }
  };

  const handleProducts = async () => {
    const { data } = await instance.get("/product");

    if (data.status) {
      setProducts(data.data);
      setFilteredProducts(data.data);
    }
  };

  useEffect(() => {
    handleProducts();
  }, []);

  const onDeleteProduct = (product) => {
    setSelectedProduct(product);
    setSureModal(true);
  };

  const onCancelDelete = () => {
    setSureModal(false);
  };

  const onEdit = (product) => {
    setSelectedProduct(product);
    setIsOpen(true);
  };

  const onSubmitDelete = async () => {
    const { data } = await instance.get(
      `/product/delete/${selectedProduct?._id}`
    );

    if (data.status) {
      showToast(data.message, "success");
      handleProducts();
    } else {
      showToast(data.message, "error");
    }

    setSureModal(false);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <div className="relative flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Ara..."
            value={search}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(search);
              }
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div
            onClick={() => onSearch(search)}
            className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
          >
            <kbd className="inline-flex items-center cursor-pointer hover:bg-gray-100 border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
              <SearchIcon className="w-4 h-5" />
            </kbd>
          </div>
        </div>
        <button
          onClick={() => setIsOpen(true)}
          type="button"
          className="inline-flex items-center ml-auto justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Ürün Ekle
        </button>
      </div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        {filteredProducts.map((product) => (
          <li
            key={product._id}
            className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
          >
            <div className="flex-1 flex flex-col p-4">
              {console.log}
              <CustomImage
                className="w-full flex-shrink-0 mx-auto rounded-md"
                src={product.banner_image}
                alt=""
              />
              <h3 className="mt-6 text-gray-900 text-xs font-medium">
                {console.log(product)}
                <div>TR: {product.name.tr}</div>
                <div>EN: {product.name.en}</div>
                <div>RU: {product.name.ru}</div>
              </h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Title</dt>
                <dd className="text-gray-500 text-sm">{product.title}</dd>
                <dt className="sr-only">Role</dt>
                <dd className="mt-3">
                  <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                    {product?.category?.name?.tr || "Kategori Yok!"}
                  </span>
                </dd>
              </dl>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                  <span
                    onClick={() => onEdit(product)}
                    className="relative cursor-pointer -mr-px w-0 flex-1 inline-flex bg-purple-400 hover:bg-purple-500 transition-all items-center justify-center py-4 text-sm text-white font-medium border border-transparent rounded-bl-lg "
                  >
                    <PencilIcon className="w-5 h-5 " aria-hidden="true" />
                    <span className="ml-3">Düzenle</span>
                  </span>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                  <span
                    onClick={() => onDeleteProduct(product)}
                    className="relative w-0 flex-1 cursor-pointer bg-red-400 hover:bg-red-500 transition-all text-white inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-br-lg hover:text-white"
                  >
                    <MinusCircleIcon className="w-5 h-5" aria-hidden="true" />
                    <span className="ml-3">Sil</span>
                  </span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <SureModal
        open={sureModal}
        setOpen={setSureModal}
        firstText={`Ürün Silme`}
        secondText={`${selectedProduct?.name?.tr} adlı ürünü silmek istediğinizden eminmisiniz?`}
        onCancel={() => onCancelDelete()}
        onSubmit={() => onSubmitDelete()}
      />
      <ProductModal
        isOpen={isOpen}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
        setIsOpen={() => {
          setTimeout(() => {
            setSelectedProduct({});
          }, 500);

          handleProducts();
          setIsOpen(false);
        }}
      />
    </div>
  );
};

export default Products;
