import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import ImageUploader from "./ImageUploader";

const CategoryModal = ({ isOpen, setIsOpen, selectedCategory }) => {
  const [category, setCategory] = useState({
    name: {
      tr: "",
      en: "",
      ru: "",
    },
    image: "",
  });
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (selectedCategory._id) {
      setCategory(selectedCategory);
    } else {
      setCategory({
        name: "",
        image: "",
      });
    }
  }, [setIsOpen, isOpen]);

  const onSubmit = async () => {
    if (!selectedCategory._id) {
      const { data } = await instance.post("/category", category);

      if (data.status) {
        showToast(data.message, "success");
        setIsOpen(false);
      } else {
        showToast(data.message, "error");
      }
    } else {
      const { data } = await instance.post(
        `/category/update/${category._id}`,
        category
      );

      if (data.status) {
        showToast(data.message, "success");
        setIsOpen(false);
      } else {
        showToast(data.message, "error");
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium flex flex-row justify-between leading-6 text-gray-900"
                >
                  <span>
                    {selectedCategory?._id
                      ? "Kategori Düzenle"
                      : "Kategori Ekle"}
                  </span>
                  <div
                    className="rounded-full p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  >
                    <XIcon className="w-6 h-6" />
                  </div>
                </Dialog.Title>
                <div className="mt-4 flex flex-col gap-4">
                  <ImageUploader
                    image={category.image}
                    text={"Resim"}
                    onChange={(imageUrl) => {
                      setCategory({ ...category, image: imageUrl });
                    }}
                  />

                  <div className="flex flex-col">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adı (TR)
                    </label>
                    <div className="mt-1">
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                        placeholder=""
                        value={category?.name?.tr}
                        onChange={(e) => {
                          setCategory({
                            ...category,
                            name: {
                              ...category.name,
                              tr: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adı (EN)
                    </label>
                    <div className="mt-1">
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                        placeholder=""
                        value={category?.name?.en}
                        onChange={(e) => {
                          setCategory({
                            ...category,
                            name: {
                              ...category.name,
                              en: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Adı (RU)
                    </label>
                    <div className="mt-1">
                      <input
                        type="name"
                        name="name"
                        id="name"
                        className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px] mt-1"
                        placeholder=""
                        value={category?.name?.ru}
                        onChange={(e) => {
                          setCategory({
                            ...category,
                            name: {
                              ...category.name,
                              ru: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSubmit}
                  >
                    {selectedCategory._id ? "Kaydet" : "Ekle"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CategoryModal;
