import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactLoading from "react-loading";
import instance from "../lib/client";
import showToast from "../utils/showToast";
import CustomImage from "./CustomImage";

const ImageUploader = ({ onChange, image, text, noText }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("file", acceptedFiles[0]);

    const { data } = await instance.post("/uploader", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status) {
      onChange(data.data);
    } else {
      showToast(data.message, "error");
    }

    // onChange({
    //   details: acceptedFiles[0],
    //   img: URL.createObjectURL(acceptedFiles[0]),
    // });

    setLoading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={`sm:grid ${
        noText
          ? "sm:grid-cols-2  sm:pt-1 border-t-none"
          : "sm:grid-cols-3 sm:border-t  sm:pt-5"
      }  sm:gap-4 sm:items-start  sm:border-gray-200`}
      {...getRootProps()}
    >
      {!noText && (
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {text || "Resim"}
        </label>
      )}
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex justify-center border-2 border-gray-300 border-dashed rounded-md">
          {loading ? (
            <div className="px-6 pt-5 pb-6">
              <ReactLoading type="spin" color="gray" />
            </div>
          ) : image ? (
            <div className="flex px-6 pt-4 pb-2 flex-col">
              <CustomImage src={image} className={"min-h-[50px]"} />
              <label
                onClick={() => onChange("")}
                className="relative w-full text-xs py-1 bg-purple-100  cursor-pointer text-center mt-1 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                Değiştir
              </label>
            </div>
          ) : (
            <div className="space-y-1 px-6 pt-5 pb-6 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer whitespace-nowrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Resim yükle</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    {...getInputProps()}
                  />
                </label>
                <p className="pl-1 whitespace-nowrap">veya buraya sürükle</p>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
